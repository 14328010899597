// StockPorfolio.tsx
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Grid, GridItem, Text, Box } from '@chakra-ui/react';
import { Empty } from 'antd';
import CChartTooltip from '../../../../components/CChartTooltip';
import EditableCell from '../EditableCell';
import Highcharts, { TooltipFormatterContextObject } from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";
import PieChart from "highcharts-react-official";
import VariablePie from "highcharts/modules/variable-pie.js";
import { convertToPercentage, convertToMillions } from '../../../../utils/commonFunctions';
import './styles.scss'

drilldow(Highcharts);
VariablePie(Highcharts);

interface ComponentProps {
  stockPortfolio: any | null;
  assetAllocation: any | null;
  allocatorData: any | null;
  assetStatus: any | null;
  mutateAssetStatus: any;
}

interface StockPorfolio {
  symbol: string;
  volume: number;
  weight: number;
}

interface StockData {
  symbol: string;
  avg_cost_price: number;
  m_price: number;
  t_price: number;
  pct_trend_value: number;
  weight: number;
  call: number;
  short: number;
  trend: string;
}

interface StockDataWithKey extends StockData {
  key: number;
}

const addKeysToData = (data: StockData[]): StockDataWithKey[] => {
  return data?.map((item, index) => ({
    key: index + 1,
    ...item
  }));
};

const renderTooltip = (point: any) => {
  return ReactDOMServer.renderToString(
    <CChartTooltip
      data={point}
    />
  );
};

const StockPorfolio: React.FC<ComponentProps> = ({ stockPortfolio, assetAllocation, allocatorData, assetStatus, mutateAssetStatus }) => {

  if (!stockPortfolio) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const stockAllocKeys: StockDataWithKey[] = addKeysToData(allocatorData);
  const totalValue = convertToMillions(stockPortfolio?.total_value ?? 0);
  const nf = new Intl.NumberFormat();

  const stockAlloc = (stockPortfolio?.stock_alloc as StockPorfolio[])?.map(item => ({
    ...item,
    name: item?.symbol,
    x: nf.format(item?.volume),
    y: convertToPercentage(item?.weight),
  }));

  // Create the chart
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [[0, '#EDF2F7']],
      },
    },
    colors: ['#BFD0EA', '#90B3E5', '#6F98D2', '#3A65A3', '#1F4172'],
    title: {
      text: totalValue,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        shadow: false,
        center: ['50%', '50%'],
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 4,
        dataLabels: {
          pointFormat:
            "<div class='pie-box'>" +
            "<b class='pie-label'>{point.name}</b><br/>" +
            "<p class='pie-value'>({point.x}, {point.y}%)</p>" +
            "</div>",
          style: {
            color: '#1F4172',
            textOutline: 'none',
          },
          useHTML: true
        },
        point: {
          events: {},
        },
      },
    },
    tooltip: {
      backgroundColor: 'rgba(255,255,255,0)',
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        return renderTooltip(this.point);
      },
    },
    series: [
      {
        name: 'Total',
        colorByPoint: true,
        innerSize: '50%',
        data: stockAlloc
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}, { id: 'versions', dataLabels: { enabled: false } }],
          },
        },
      ],
    },
  };

  return (
    <>
      <Box>
        <Grid position={'relative'}>
          <Text align={'center'} fontSize={'2xl'}>TỶ TRỌNG CỔ PHIẾU TRONG DANH MỤC</Text>
          <GridItem>
            <Box>
              <PieChart highcharts={Highcharts} options={options} />
            </Box>
          </GridItem>
          {
            stockAllocKeys && (
              <EditableCell stockAllocKeys={stockAllocKeys} assetAllocation={assetAllocation} mutateAssetStatus={mutateAssetStatus} />
            )
          }
        </Grid>
      </Box>
    </>
  );
};

export default StockPorfolio;
