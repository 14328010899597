import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';
export default function usePutCustomerData(params) {
  const { token, isLogin } = useProvideAuth();
  const [customerDataPut, setCustomerDataPut] = useState(null);
  const [customerErrorPut, setCustomerErrorPut] = useState(null);

  const putCustomerData = async (params) => {
    if (params) {
      try {
        const responseCustomer = await fetch(`${process.env.REACT_APP_BACKEND}/v1/customer/edit/?account_id=${params.account_id}`, {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });

        const customer = await responseCustomer.json();
        if (responseCustomer.ok) {
          const responseContractData = await fetch(`${process.env.REACT_APP_BACKEND}/v1/customer/contract/newest/?account_id=${params.account_id}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });

          const contractData = await responseContractData.json();
          if (responseContractData.ok) {
            params.cust_start_date = dayjs(params.cust_start_date, 'D/M/YYYY').format(dateFormat);
            params.cust_end_date = dayjs(params.cust_end_date, 'D/M/YYYY').format(dateFormat);
            
            const responseContract = await fetch(`${process.env.REACT_APP_BACKEND}/v1/customer/contract/edit/?contract_id=${contractData.data[0].id}`, {
              method: 'PUT',
              headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(params),
            });
            
            const contract = await responseContract.json();
            if (responseContract.ok) {
              setCustomerDataPut(contract);
            } else {
              setCustomerErrorPut(contract.detail);
            }
          } else {
            setCustomerErrorPut(contractData.detail);
          }
        } else {
          setCustomerErrorPut(customer.detail);
        }
      } catch (error) {
        setCustomerErrorPut(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      putCustomerData(params);
    }
  }, [isLogin, token, params]);

  return { customerDataPut, customerErrorPut, mutate: putCustomerData };
}