import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';
export default function usePostCustomerData(params) {
  const { token, isLogin } = useProvideAuth();
  const [customerData, setCustomerData] = useState(null);
  const [customerError, setCustomerError] = useState(null);

  const postCustomerData = async (params) => {
    if (params) {
      try {
        const responseCustomer = await fetch(`${process.env.REACT_APP_BACKEND}/v1/customer`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });
        const responseCustomerData = await responseCustomer.json();
        if (responseCustomer.ok) {
          params.cust_start_date = dayjs(params.cust_start_date, 'D/M/YYYY').format(dateFormat);
          params.cust_end_date = dayjs(params.cust_end_date, 'D/M/YYYY').format(dateFormat);
          
          const responseContract = await fetch(`${process.env.REACT_APP_BACKEND}/v1/customer/contract`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
          });

          const responseContractData = await responseContract.json();
          if (responseCustomer.ok) {
            setCustomerData(responseContractData);
          } else {
            setCustomerError(responseContractData.detail);
          }
        } else {
          setCustomerError(responseCustomerData.detail);
        }
      } catch (error) {
        setCustomerError(error.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      postCustomerData(params);
    }
  }, [isLogin, token, params]);

  return { customerData, customerError, mutate: postCustomerData };
}