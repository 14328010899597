import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useContractNewest(params) {
  const { isLogin } = useProvideAuth();
  const [contractNewest, setContractNewest] = useState(null);

  const { data, error, mutate } = useFetchData('customer/contract/newest', params);

  useEffect(() => {
    if (data && isLogin) {
      setContractNewest(data[0]);
    }
    if (error) {
      setContractNewest(null);
    }
  }, [data, error, isLogin]);

  return { contractNewest, mutate };
}