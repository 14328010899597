import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Text } from '@chakra-ui/react';
import moment from 'moment';

interface PortfolioPerformanceEntry {
  date: string;
  ror: number;
  vnindex_ror: number;
}

interface ComponentProps {
  portfolioPerformance: PortfolioPerformanceEntry[] | null;
}

const PortfolioPerformance: React.FC<ComponentProps> = ({ portfolioPerformance }) => {

  if (!portfolioPerformance || portfolioPerformance.length === 0) {
    return <Text align={'center'} fontSize={'xl'}>No data available</Text>;
  }

  const dates = portfolioPerformance.map((item) => moment(item.date).format('D MMM')) || [];
  const rors = portfolioPerformance.map((item) => parseFloat((item.ror * 100).toFixed(2))) || [];
  const vnindexRors = portfolioPerformance.map((item) => parseFloat((item.vnindex_ror * 100).toFixed(2))) || [];

  const options = {
    chart: {
      type: 'spline',
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      categories: dates?.reverse(),
      allowDecimals: false,
    },
    yAxis: {
      title: {
        text: 'Percentage (%)'
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
          radius: 4,
          states: {
            hover: {
              enabled: true
            }
          }
        },
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 4
          }
        }
      }
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      valueSuffix: ' %'
    },
    series: [{
      name: 'Portfolio',
      data: rors?.reverse(),
      color: '#21335b'
    }, {
      name: 'VNIndex',
      data: vnindexRors?.reverse(),
      color: '#FF2929'
    }],
  };

  return (
    <div>
      <Text align={'center'} fontSize={'2xl'}>HIỆU QUẢ DANH MỤC ĐẦU TƯ</Text>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PortfolioPerformance;
