// Header.tsx
import React, { useState } from 'react';
import { Box, Container, Heading, Grid, Flex, Text, chakra } from '@chakra-ui/react';
import { jwtDecode } from "jwt-decode";
import { Logo } from '../Logo';
import type { DatePickerProps, MenuProps } from 'antd';
import { DatePicker, Dropdown, Avatar, Space } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from "moment";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import './styles.scss';

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';

interface ComponentProps {
  customer: any | null
}

const Header: React.FC<ComponentProps> = ({ customer = null }) => {

  const [date, setDate] = useState(localStorage.getItem('currentDate') || moment().format(dateFormat).toString());
  const isLogin = localStorage.getItem('isLogin') || false;
  const jwtToken = localStorage.getItem('jwtToken') || '';
  const decoded = jwtToken ? jwtDecode(jwtToken) : null;
  const user = decoded ? (decoded as { data: any })['data'] : null;

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(date.format(dateFormat).toString());
    localStorage.setItem('currentDate', date.format(dateFormat).toString());
    window.location.reload();
  };

  const hanleLogout = () => {
    localStorage.clear();
    window.location.reload();
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <>
          <Heading fontSize="sm" minW={'150px'}>{user?.name}</Heading>
          <Text fontSize="xs" textTransform={'capitalize'} fontStyle={'italic'}>{user?.role_name}</Text>
        </>
      ),
      key: '1',
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: <a href="javascript:;" onClick={() => hanleLogout()}>Logout</a>,
      key: '2',
      icon: <LogoutOutlined />,
    }
  ];

  return (
    <Box className='header' bg="brand.500" p={2} boxShadow='base'>
      <Container justifyContent="space-between" maxW="container.6xl" mx="auto">
        <Grid templateColumns={customer && customer[0] ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)'} gap={6} alignItems={'center'}>
          <Box>
            <Logo />
          </Box>
          {customer && customer[0] && (
            <Box textAlign={'center'}>
              <Heading fontSize="md" color={'white'}>{`${customer[0].name} - ${customer[0].account_id}`}</Heading>
            </Box>
          )}
          {isLogin && (
            <Box className='right'>
              <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                <Space size={0}>
                  <Text fontSize={{ base: 'xs', lg: 'sm' }} color={'white'}>
                    Dữ liệu cập nhật đến ngày
                  </Text>
                  <DatePicker
                    className='c-ant-picker'
                    allowClear={false}
                    suffixIcon={
                      <chakra.img className='calendar' src={process.env.PUBLIC_URL + '/resources/images/calendar.svg'} />
                    }
                    value={dayjs(date, dateFormat)}
                    maxDate={dayjs()}
                    format={'DD/MM/YYYY'}
                    onChange={onChange}
                    inputReadOnly={true}
                  />
                </Space>
                {
                  user && (
                    <Dropdown menu={{ items }} trigger={['click']}>
                      <Avatar size="large" icon={<UserOutlined />} />
                    </Dropdown>
                  )
                }
              </Flex>
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
